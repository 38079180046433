.top-selling-box h2 {
    font-size: 1.4rem; /* ขนาดฟอนต์ตามมาตรฐาน */
    color: #333;
  }
  
  .top-selling-table th, .top-selling-table td {
    font-size: 1.0rem; /* ปรับขนาดฟอนต์ */
  }
  
  @media (max-width: 768px) {
    .top-selling-box h2 {
      font-size: 1.2rem; /* ปรับขนาดฟอนต์สำหรับหน้าจอขนาดเล็ก */
    }
  
    .top-selling-table th, .top-selling-table td {
      font-size: 1.1rem; /* ขนาดฟอนต์เล็กลงสำหรับหน้าจอมือถือ */
    }
  }
  
  @media (min-width: 992px) {
    .top-selling-box h2 {
      font-size: 1.4rem; /* ขนาดฟอนต์ใหญ่ขึ้นสำหรับหน้าจอใหญ่ */
    }
  
    .top-selling-table th, .top-selling-table td {
      font-size: 1.0rem; /* ขนาดฟอนต์ใหญ่ขึ้นสำหรับหน้าจอใหญ่ */
    }
  }
  