.n3-award1-item {
    display: flex;
    /*flex-wrap: wrap;*/
    justify-content: center; /* จัดให้อยู่ตรงกลาง */
    gap: 10px; /* เพิ่มระยะห่างระหว่างตัวเลข */
    padding: 1rem;
    border-radius: 0.6rem;
  }
  .n3-award1-item-sub {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    color: #0873ba;
    margin: 0 5px; /* เพิ่มระยะห่างระหว่างตัวเลข */
  }
.n3-award2-item {
    display: flex;
    /*flex-wrap: wrap;*/
    justify-content: center; /* จัดให้อยู่ตรงกลาง */
    gap: 10px; /* เพิ่มระยะห่างระหว่างตัวเลข */
    padding: 1rem;
    border-radius: 0.6rem;
  }
  
  .n3-award2-item-sub {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    color: #0873ba;
    margin: 0 5px; /* เพิ่มระยะห่างระหว่างตัวเลข */
  }

  .n3-award2>h2 {
    font-size: 1.0rem;
        width: auto;
  }

  @media only screen and (min-width: 75em) {
    .award-current-box>.row2 .n3-award1 {
        padding: 0 2rem;
    }
}

  @media only screen and (min-width: 75em) {
    .award-current-box>.row2 .n3-award2 {
        padding: 0 3rem;
    }
}
  
  .n3-award-item {
    flex: 1;
    min-width: 200px;
    margin: 10px;
    background-color: #fff;
    border-radius: 10px;
    padding: 1rem;
    text-align: center;
    border-right: 1px solid #e2e7ec;
  }

  @media only all and (min-width: 48em) {
    .award-current-box>.row2 .n3-award1 {
        width: 25%;
        border-bottom: 0;
        border-right: 1px solid #e2e7ec;
    }
}

@media only screen and (min-width: 62em) {
    .award-current-box>.row2 .n3-award2 h2 {
        font-size: 1.0rem;
        width: auto;
    }
}
@media only all and (min-width: 48em) {
    .award-current-box>.row2 .n3-award2 h2 {
        font-size: 1rem;
        padding-bottom: 1rem;
        width: 100%;
    }
}
.award-current-box>.row2 .n3-award2 h2 {
    line-height: 1.1;
    align-self: center;
    text-align: center;
}

@media only screen and (min-width: 75em) {
    .award-current-box>.row2 .n3-award3 {
        padding: 0 2rem;
    }
}
@media only screen and (min-width: 62em) {
    .award-current-box>.row2 .n3-award3 {
        padding: 0 1.8rem;
    }
}
@media only all and (min-width: 48em) {
    .award-current-box>.row2 .n3-award3 {
        width: 23%;
        padding: 0 1.4rem;
    }
}
.award-current-box>.row2 .n3-award3 {
    padding-bottom: .6rem;
}

@media only screen and (min-width: 62em) {
    .award-current-box>.row2 .n3-award3 h2 {
        font-size: 1rem;
        width: auto;
    }
}
@media only all and (min-width: 48em) {
    .award-current-box>.row2 .n3-award3 h2 {
        font-size: 1rem;
        padding-bottom: 1rem;
        width: 100%;
    }
}
.award-current-box>.row2 .n3-award3 h2 {
    line-height: 1.1;
    align-self: center;
    text-align: center;
}

.award-current-box>.row2 .n3-award3-item {
    letter-spacing: .1rem;
    border-radius: 1rem;
    text-align: center;
}

@media only screen and (min-width: 62em) {
    .award-current-box>.row2 .n3-award3-item , .award-current-box>.row2 .n3-award3-item p, .award-current-box>.row2 .n3-award3-item li {
        margin-right: 0rem;
    }
}
@media only screen and (min-width: 62em) {
    .award-current-box>.row2 .n3-award3-item-sub {
        margin: 1.0rem 0;
        font-size: 2.2rem;
    }
}
@media only all and (min-width: 48em) {
    .award-current-box>.row2 .n3-award3-item-sub {
        font-size: 1.8rem;
    }
}
.award-current-box>.row2 .n3-award3-item-sub {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    color: #0873ba;
}

@media only all and (min-width: 48em) {
    .award-current-box>.row2 .n3-award2 {
        /*width: 23%;*/
        padding: 0 2.7rem;
        border-bottom: 0;
        border-right: 1px solid #e2e7ec;
    }
}

.prize-amount {
    background: linear-gradient(90deg, #FFF090, #FFFFB0, #FFF090); /* ไล่สีจากทองเข้มไปทองอ่อน */
    color: #a86c05; /* สีตัวอักษร */
    padding: 8px 10px;
    border-radius: 8px;
    display: inline-block;
    margin-top: 10px;
    font-size: 1rem; /* ปรับขนาดตามต้องการ */
    font-weight: normal;
    text-align: center; /* จัดให้อยู่ตรงกลาง */
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); */ /* เพิ่มเงาเล็กน้อย */
 }

  .prize-amount-number {
    font-weight: 600; /* หนาเฉพาะตัวเลข */
  }

  


  .award-current-box>.row2 .n3-award1 h2 {
    line-height: 1.1;
    align-self: center;
    text-align: center;
}
@media only all and (min-width: 48em) {
    .award-current-box>.row2 .n3-award1 h2 {
        font-size: 1rem;
        padding-bottom: 1rem;
        width: 100%;
    }
}
@media only screen and (min-width: 62em) {
    .award-current-box>.row2 .n3-award1 h2 {
        font-size: 1.0rem;
        width: auto;
    }
}
.n3-award1>h2 {
    font-size: 1.0rem;
    width: auto;
}
  
  