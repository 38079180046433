.App {
  text-align: center;
  font-family: 'Noto Sans Thai', sans-serif;
}

.App-header {
  /*background-color: #f8f9fa; *//* หรือสีอื่นที่คุณต้องการ */
  padding-top: 20px; /* เพิ่มระยะห่างด้านบน */
  min-height: calc(100vh - 20px); /* ลดความสูงถ้าจำเป็น */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* เริ่มต้นจากด้านบนแทนของ center */
}

.dashboard-panel {
  background: #ffffff;
  /*border-radius: 20px;*/
  padding: 20px;
  /*box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);*/
  width: auto; /* ให้ขนาดตามข้อมูล */
  max-width: 100%; /* ให้ไม่เกินกว้างของหน้าจอ */
  overflow-x: auto; /* ให้มี scrollbar ถ้าตารางกว้างเกินไป */
}

.table {
  margin-bottom: 0; /* ลบ margin ด้านล่างของ bootstrap */
}

/* สไตล์หัวตาราง */
.thead th {
  background-color: #e9ecef; /* สีพื้นหลังหัวตาราง */
  color: #333; /* สีตัวอักษร */
}

/* สไตล์เนื้อหาตาราง */
.tbody tr {
  background-color: #f8f9fa; /* สีพื้นหลังเนื้อหา */
  color: #333; /* สีตัวอักษร */
}

/* ปรับเนื้อหาให้มีขอบมน */
.table th, .table td {
  border-radius: 0.75rem;
}

/* ปรับเนื้อหาเมื่อ hover */
.tbody tr:hover {
  background-color: #ececec; /* สีพื้นหลังเมื่อ hover */
}

.background-numbers {
  font-size: 48px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; /* หลีกเลี่ยงการส่งผลกระทบต่อการโต้ตอบกับองค์ประกอบอื่น */
}

.background-numbers span {
  position: absolute;
  opacity: 0.2; /* เริ่มต้นค่าความโปร่งใส */
}

.background-numbers span.color-1 { color: #d16a6a; }
.background-numbers span.color-2 { color: #f0be74; }
.background-numbers span.color-3 { color: #FDE767; }
.background-numbers span.color-4 { color: #6895D2; }
.background-numbers span.color-5 { color: #48b688; }

/* ปรับให้ตรงกับภาพที่ให้มา, ลองเพิ่มสไตล์ที่ขาดหายไป และปรับสีให้ตรงตามความต้องการของคุณ */
.prize-highlight {
  background-color: #ffec3d; /* สีเหลือง */
  padding: 5px 10px;
  border-radius: 8px; /* ขอบมน */
  display: inline-block; /* ให้ปรับขนาดตามเนื้อหา */
  margin: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); /* เงาเล็กน้อย */
}

/* สไตล์ทั่วไปของข้อมูลรางวัล */
.prize-info {
  display: flex;
  align-items: center;
  justify-content: space-evenly; /* กระจายพื้นที่เท่ากันระหว่างองค์ประกอบ */
  padding: 10px;
  background: #f0f0f0; /* สีพื้นหลังที่เบากว่า */
  border-radius: 8px;
  margin-top: 20px; /* ระยะห่างจากด้านบน */
}

/* สไตล์สำหรับหมายเลขรางวัลที่เน้น */
.number-highlight {
  font-size: 1.2em;
  font-weight: bold;
  color: #000; /* สีตัวอักษร */
}

/* ตัวอย่างสไตล์สำหรับปุ่ม, ปรับให้เข้ากับภาพที่คุณส่งมา */
.button {
  background-color: #1890ff; /* สีน้ำเงิน */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #40a9ff; /* สีน้ำเงินที่สว่างขึ้นเมื่อ hover */
}

.button-secoundary:hover {
  transition:.3s ease-in-out; 
  color:#fff; 
  background:#0873ba; 
}

/* award current box - container */
@media (min-width: 576px) {
  .container, .container-sm {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
      max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 1140px;
  }
}

.award-current-box {
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;

  line-height: 1.1739;
  text-align: left;
}

div[class*=-box] {
  box-shadow: 3px 3px 9px 0 rgba(75,126,171,.25);
}

.award-current-box>.row1 {
  margin: 1rem 0 0;
}

.donate-stat .donate-type-stat-table thead tr:nth-child(2) th, .pay-box-item .item-search-dropdown select, .pay-box-item_ems .item-search-dropdown select, .service-sample .accordion .card-item-header h2 span, .sla-content-2 .sell-block table td ul li, .sla-content-2 .sell-block table th:last-child, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

.service-sample .accordion .card-item-header h2 span, .sla-content-2 .sell-block table th:last-child, h1 {
    font-weight: 400;
    font-size: 2.8rem;
    color: #4a4a4a;
}

.award-current-box>.row1 h2 {
  color: #0873ba;
}

a, a:hover {
  color: inherit;
}
a {
  text-decoration: none !important;
}

.button-primary, .button-secoundary {
  padding: 0.8rem;
  text-align: center;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 27.5px;
  font-weight: 400;
  font-size: 1.6rem;
}

.button-primary:hover, .button-secoundary {
  color: #0873ba;
  background: #fff;
  border: 1px solid #0873ba;
}

.award-current-box>.row1 .button-home>.col-6,.award-current-box>.row1 .pay-item-content>.col-6 {
  padding: .6rem;
  box-sizing: border-box;
  text-align: center;
  line-height: 1.1;
  vertical-align: middle
}

.award-current-box>.row1 .button-home>.col-6 .button-secoundary img,.award-current-box>.row1 .pay-item-content>.col-6 .button-secoundary img {
  padding-right: .3rem;
  width: 16spx;
  width: 22px
}

.award-current-box>.row1 .button-home>.col-6 .button-secoundary:hover img,.award-current-box>.row1 .pay-item-content>.col-6 .button-secoundary:hover img {
  filter: brightness(0) invert(1)
}

.award-current-box>.row1 .button-home>.col-6 .button-secoundary:before,.award-current-box>.row1 .pay-item-content>.col-6 .button-secoundary:before {
  vertical-align: middle;
  font-weight: 100
}

.award-current-box>.row1 .button-home>.col-6 .button-primary:after,.award-current-box>.row1 .pay-item-content>.col-6 .button-primary:after {
  content: " ";
  font-family: "fontello";
  vertical-align: middle;
  font-weight: 600
}

.award-current-box>.row2 {
  margin: 2rem 0 0
}

.award-current-box>.row2 .award {
  padding: 0 2rem
}

.award-current-box>.row2 .award1 {
  width: 100%;
  border-bottom: 1px solid #e2e7ec;
  padding-bottom: .6rem
}

.award-current-box>.row2 .award1 h2 {
  width: 50%
}

.award-current-box>.row2 .award1-item {
  padding: .4rem 0;
  margin: 1rem 0;
  letter-spacing: .5rem;
  text-align: center;
  background: #ffea2f;
  border-radius: 0.6rem
}

.award-current-box>.row2 .award1-item-sub {
  font-family: "Roboto",sans-serif;
  font-weight: 700;
  font-size: 4.3rem;
  color: #4a4a4a; 
  letter-spacing: 0.25rem
}

.award-current-box>.row2 .award2 {
  width: 100%;
  margin: 0.5rem 0;
  border-bottom: 1px solid #e2e7ec;
  padding-bottom: .6rem
}

.award-current-box>.row2 .award2 h2 {
  width: 50%;
  line-height: 1.1;
  align-self: center;
  text-align: center
}

.award-current-box>.row2 .award2-item {
  text-align: center;
  border-radius: 1rem
}

.award-current-box>.row2 .award2-item-sub {
  font-family: "Roboto",sans-serif;
  font-weight: 700;
  font-size: 2.3rem;
  color: #0873ba; 
}

.award-current-box>.row2 .award2-item .sla-content-2 .sell-block table td ul li,.award-current-box>.row2 .award2-item p,.sla-content-2 .sell-block table td ul .award-current-box>.row2 .award2-item li {
  margin-right: 0.8rem
}

.award-current-box>.row2 .award3 {
  width: 100%;
  margin: 0.5rem 0;
  padding-bottom: .6rem
}

.award-current-box>.row2 .award3 h2 {
  width: 50%;
  line-height: 1.1;
  align-self: center
}

.award-current-box>.row2 .award3-item {
  letter-spacing: .1rem;
  border-radius: 1rem; 
  text-align: center
}

.award-current-box>.row2 .award3-item-sub {
  font-family: "Roboto",sans-serif;
  font-weight: 700;
  font-size: 2.3rem;
  color: #0873ba
}

.award-current-box>.row2 .button-home>.col-6:first-child {
  padding-right: .5rem
}

.award-current-box>.row2 .button-home>.col-6:nth-child(2) {
  padding-left: .5rem
}

.award-current-box>.row2 .button-home .button-primary,.award-current-box>.row2 .button-home .button-secoundary {
  padding: 1.2rem 0
}

.award-current-box>.row2 .button-home .button-primary img,.award-current-box>.row2 .button-home .button-secoundary img {
  padding-right: .5rem;
  width: 22px
}

.award-current-box>.row2 .button-home .button-primary:hover img,.award-current-box>.row2 .button-home .button-secoundary:hover img {
  filter: brightness(0) invert(1)
}

.award-current-box>.row2 .button-home .button-primary:after {
  content: " ";
  font-family: "fontello";
  vertical-align: middle;
  font-weight: 600
}

.award-current-box>.row2 .button-home .button-secoundary:before {
  vertical-align: middle;
  font-weight: 100
}

.award-current-box .row.row1 div h1 {
  font-family: 'Noto Sans Thai', sans-serif;
  font-weight: 500;
  letter-spacing: -0.04rem;
}

.award-current-box .row.row1 div h2 {
  font-family: 'Noto Sans Thai', sans-serif;
  font-weight: 500;
  letter-spacing: -0.03rem;
  line-height: 1.4;
  font-size: 1.25rem;
}

.award-history-box {
  position: relative;
  z-index: 2;
  background: #fff;
  border-radius: 10px;
  padding: 1rem;
  line-height: 1.1739;
  text-align: left;
}

.margin {
  margin-top: 20px;
}

.contact-popup {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.contact-popup p {
  margin: 0;
}

.contact-popup button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-left: 10px;
}

.dashboard-panel>.table>tbody>tr>td>a>.fas.fa-file-pdf {
  font-size: 1rem;
  cursor: pointer;
  padding-right: 10px;
}

.dashboard-panel>.table>tbody>tr>td>a {
  text-align: center;
  font-size: 0.9rem;
  cursor: pointer;
  font-weight: 400;
  color: #0873ba;
}

.award-history-box>div>.btn-extra-sm {
  margin-top: 7px;
  margin-right: 5px;
  border-radius: 20px;
  padding: 0.25rem 0.5rem; /* ลดขนาด padding */
  font-size: 0.75rem; /* ลดขนาดข้อความ */
  line-height: 1.5; /* อาจปรับ line-height ถ้าจำเป็น */
}

.dashboard-panel>.table>tbody>tr>td>button {
  margin-top: 0px;
  margin-right: 5px;
  border-radius: 20px;
  padding: 0.1rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

/* เพิ่ม CSS สำหรับ Modal */
.custom-modal {
  font-family: 'Noto Sans', sans-serif; /* กำหนดแบบอักษรให้เป็น Noto Sans */
}

.modal-title,.modal-body {
  font-family: 'Noto Sans Thai';
}

.monthy-btn {
  background-color: #3a833a; /* สีพื้นหลัง */
  border: none;
  color: #fff; /* สีข้อความ */
  border-radius: 20px; /* โค้งมนขอบ */
  /*box-shadow: 0 4px 8px rgba(0, 123, 255, .5);*/ /* เงา */
  padding: .375rem .75rem; /* การเติม */
  margin-right: .375rem; /* ระยะขอบขวา */
  margin-top: .375rem; /* ระยะขอบขวา */
  transition: all .2s; /* การเปลี่ยนแปลงที่มีแอนิเมชั่น */
}

.monthy-btn:hover {
  background-color: #2c632c; /* สีพื้นหลัง */
}

.yearly-btn {
  background-color: #3a833a; /* สีพื้นหลัง */
  border: none;
  color: #fff; /* สีข้อความ */
  border-radius: 20px; /* โค้งมนขอบ */
  /*box-shadow: 0 4px 8px rgba(0, 123, 255, .5);*/ /* เงา */
  padding: .375rem .75rem; /* การเติม */
  margin-right: .375rem; /* ระยะขอบขวา */
  margin-top: .375rem; /* ระยะขอบขวา */
  transition: all .2s; /* การเปลี่ยนแปลงที่มีแอนิเมชั่น */
}

.yearly-btn:hover {
  background-color: #2c632c; /* สีพื้นหลัง */
}

@media (max-width: 768px) {
  .hide-on-mobile {
    display: none;
  }
}

.award-history-box > h2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.award-history-box > h2 > .info {
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  cursor: pointer;
}

.award-history-box > h2 > .info > i {
  color: #fcb111;
  margin-left: 5px;
  margin-right: 5px;
}

.number-summary {
  font-size: 1rem; /* ลดขนาดข้อความ */
}

.number-summary > hr {
  margin: 0.5rem, 0; /* ลดขนาดข้อความ */
}

.repeated-numbers {
  display: flex;
  gap: 5px;
  font-weight: bold;
}

.repeated-numbers span {
  margin-left: 5px; /* หรือปรับแต่งตามที่ต้องการ */
}

.summary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only all and (min-width: 36em) {
  .button-primary, .button-secoundary {
    font-size: 0.8rem;
  }
} 

@media only all and (min-width: 48em) {
  .service-sample .accordion .card-item-header h2 span, .sla-content-2 .sell-block table th:last-child, h1 {
      font-size: 1.4rem;
  }
  h2 {
    font-size: 1.0rem;
  }
  .button-primary, .button-secoundary {
    font-size: 0.8rem;
  }
  .award-current-box>.row1 .button-home>.col-6,.award-current-box>.row1 .pay-item-content>.col-6 {
    padding:1rem 0 0 1rem
  }
  .award-current-box>.row1 .button-home>.col-6 .button-secoundary img,.award-current-box>.row1 .pay-item-content>.col-6 .button-secoundary img {
    width:18px
  } 
  .award-current-box>.row2 .award1 {
    width:31%;
    border-bottom: 0;
    border-right: 1px solid #e2e7ec
  }
  .award-current-box>.row2 .award1 .hilight {
    font-weight: 700;
    font-size: 0.9rem
  } 
  .award-current-box>.row2 .award1-item-sub {
    font-size:2.5rem
  }
  .award-current-box>.row2 .award1-item {
    width:86%
  }
  .award-current-box>.row2 .award2 {
    width:23%;
    padding: 0 1.1rem;
    border-bottom: 0;
    border-right: 1px solid #e2e7ec
  } 
  .award-current-box>.row2 .award2 h2 {
    font-size:0.8rem;
    padding-bottom: 1rem;
    width: 100%
  }
  .award-current-box>.row2 .award2-item-sub {
    font-size:1.8rem
  }
  .award-current-box>.row2 .award3 {
    width:23%;
    padding: 0 1.4rem
  }
  .award-current-box>.row2 .award3 h2 {
    font-size:0.8rem;
    padding-bottom: 1rem;
    width: 100%
  }
  .award-current-box>.row2 .award3-item-sub {
    font-size:1.8rem
  }
} 

@media only screen and (min-width: 62em) {
  .award-current-box {
    padding: 0.9rem 1.5rem 2rem 3.5rem;
  }
  .service-sample .accordion .card-item-header h2 span, .sla-content-2 .sell-block table th:last-child, h1 {
    font-size: 2.0rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  .button-primary, .button-secoundary {
    font-size: 0.8rem;
    /*padding: 1.2rem 0.75rem;*/
  }
  .award-current-box>.row2 .award1 .hilight {
    font-weight: 700;
    font-size: 1.3rem
  }
  .award-current-box>.row2 .award1 h2 {
    font-size:2.3rem;
    width: auto
  }
  .award-current-box>.row2 .award1-item-sub {
    font-size:3.0rem
  }
  .award-current-box>.row2 .award2 {
    padding:0 1.8rem
  }
  .award-current-box>.row2 .award2 h2 {
    font-size:1.0rem;
    width: auto
  }
  .award-current-box>.row2 .award2-item-sub {
    margin: 1.0rem 0;
    font-size: 2.2rem
  }
  .award-current-box>.row2 .award2-item .sla-content-2 .sell-block table td ul li,.award-current-box>.row2 .award2-item p,.sla-content-2 .sell-block table td ul .award-current-box>.row2 .award2-item li {
    margin-right: 1rem
  }
  .award-current-box>.row2 .award3 h2 {
    font-size:1rem;
    width: auto
  }
  .award-current-box > .row.row1 > .col-12.col-md-6.col-lg-8 > h1 {
    font-size:2rem;
  }
  .award-current-box>.row2 .award3 {
    padding:0 1.8rem
  }
  .award-current-box>.row2 .award3-item-sub {
    margin:1.0rem 0;
    font-size: 2.2rem
  }
  .award-current-box>.row2 .award3-item .sla-content-2 .sell-block table td ul li,.award-current-box>.row2 .award3-item p,.sla-content-2 .sell-block table td ul .award-current-box>.row2 .award3-item li {
    margin-right:0.5rem
  }
}

@media only screen and (min-width: 75em) {
  .button-primary, .button-secoundary {
    font-size: 0.8rem;
  }
  .award-current-box>.row1 .button-home>.col-6,.award-current-box>.row1 .pay-item-content>.col-6 {
    padding:0 0 0 1rem
  }
  .award-current-box>.row2 .award2 {
      padding:0 3rem
  }
  .award-current-box>.row2 .award3 {
    padding:0 2rem
  }
}



.high-frequency-top-green {
  color: green;
  font-weight: bold;
  font-size: 2em;
}

.high-frequency-top-blue {
  color: blue;
  font-weight: bold;
  font-size: 1.5em;
}

.high-frequency-red {
  color: red;
}

.high-frequency-orange {
  color: orange;
}

.top-selling-box {
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
}

.top-selling-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.top-selling-table th, .top-selling-table td {
  padding: 8px;
  border: 1px solid #ccc;
}

.top-selling-table th {
  background-color: #007bff;
  color: white;
}

.top-selling-table td {
  font-size: 1.2rem;
}





